import text from '../../data/text.json'
import {InputText} from 'primereact/inputtext'
import {Controller, useForm} from 'react-hook-form'
import {Dropdown} from 'primereact/dropdown'
import {Calendar} from 'primereact/calendar'
import {InputTextarea} from 'primereact/inputtextarea'
import {Button} from 'primereact/button'
import {useEffect, useState} from 'react'
import Select from '../../api/select'
import {ProgressSpinner} from 'primereact/progressspinner'
import Users from '../../api/users'
import moment from 'moment'
import {Checkbox} from 'primereact/checkbox'

const WorkerForm = ({setModal, modal, fetchUsers}) => {
    const lang = 'az'
    const [duties, setDuties] = useState([])
    const [isFetching, setIsFetching] = useState(false)
    const [loader, setLoader] = useState(false)
    const prefixElement = prefix => {
        return {
            value: prefix,
            label: prefix
        }
    }
    const prefixes = [
        prefixElement('050'),
        prefixElement('051'),
        prefixElement('055'),
        prefixElement('070'),
        prefixElement('077'),
        prefixElement('099'),
        prefixElement('010')
    ]

    const roles = [
        {
            value: 1,
            label: text[lang].admin
        },
        {
            value: 2,
            label: text[lang].doctor
        },
        {
            value: 3,
            label: text[lang].reception
        },
        {
            value: 4,
            label: text[lang].admin_and_doctor
        }
    ]

    const {control, handleSubmit, reset, formState: {errors}} = useForm({
        defaultValues: {
            phone_prefix: prefixes[0].value
        }
    })

    const create = async data => {
        setLoader(true)
        const formData = {
            ...data,
            birth: data?.birth ? moment(data?.birth).format('YYYY-MM-DD') : null
        }
        try {
            if (modal?.id) {
                delete formData.photo
                await Users.update(formData)
            } else {
                await Users.create(formData)
            }
            fetchUsers()
            setModal(null)
        } catch (e) {
            console.log('Error')
        } finally {
            setLoader(false)
        }
    }

    const fetchDuties = async() => {
        setIsFetching(true)
        const data = await Select.getDuties()
        setDuties(data)
        setIsFetching(false)
    }

    useEffect(() => {
        fetchDuties()
        if (modal?.id) {
            reset({
                ...modal,
                birth: new Date(modal?.birth)
            })
        }
    }, [])

    return (
        isFetching ? (
            <div className="flex justify-content-center">
                <ProgressSpinner/>
            </div>
        ) : (
            <form onSubmit={handleSubmit(create)} className="grid w-full">
                <Controller render={({field: {value, onChange}}) => (
                    <div className="col-12">
                        <div className="flex align-items-center">
                            <Checkbox inputId="can_view_in_site" name="can_view_in_site" value={value}
                                onChange={onChange} checked={value === 1 || value === true}/>
                            <label htmlFor="can_view_in_site" className="ml-2">{text[lang].view_in_site}</label>
                        </div>
                    </div>
                )} name="can_view_in_site" control={control}/>
                <Controller render={({field: {value, onChange}}) => (
                    <div className="col-12">
                        <div className="flex align-items-center">
                            <Checkbox inputId="can_view_in_site_meeting" name="can_view_in_site_meeting" value={value}
                                onChange={onChange} checked={value === 1 || value === true}/>
                            <label htmlFor="can_view_in_site_meeting"
                                className="ml-2">{text[lang].view_in_site_randevu}</label>
                        </div>
                    </div>
                )} name="can_view_in_site_meeting" control={control}/>
                <Controller rules={{
                    required: true
                }} control={control} render={({field: {value, onChange}}) => (
                    <div className="col-12 md:col-6">
                        <label htmlFor="name">{text[lang].name}</label>
                        <InputText
                            className="w-full"
                            value={value}
                            onChange={onChange}
                            id="name"
                            aria-describedby="name"
                            placeholder={text[lang].name}
                        />
                        {errors.name && (
                            <small className="form-element-is-invalid">
                                {text[lang].field_required}
                            </small>
                        )}
                    </div>
                )} name="name"/>
                <Controller rules={{
                    required: true
                }} control={control} render={({field: {value, onChange}}) => (
                    <div className="col-12 md:col-6">
                        <label htmlFor="surname">{text[lang].surname}</label>
                        <InputText
                            className="w-full"
                            value={value}
                            onChange={onChange}
                            id="surname"
                            aria-describedby="surname"
                            placeholder={text[lang].surname}
                        />
                        {errors.surname && (
                            <small className="form-element-is-invalid">
                                {text[lang].field_required}
                            </small>
                        )}
                    </div>
                )} name="surname"/>
                <Controller control={control} render={({field: {value, onChange}}) => (
                    <div className="col-12 md:col-6">
                        <label htmlFor="father">{text[lang].father_name}</label>
                        <InputText
                            className="w-full"
                            value={value}
                            onChange={onChange}
                            id="father"
                            aria-describedby="father"
                            placeholder={text[lang].father_name}
                        />
                        {errors.father && (
                            <small className="form-element-is-invalid">
                                {text[lang].field_required}
                            </small>
                        )}
                    </div>
                )} name="father"/>
                <div className="col-12 md:col-6">
                    <label htmlFor="phone_prefix">{text[lang].mobile_phone}</label>
                    <div className="w-full flex gap-1">
                        <Controller control={control} render={({field: {value, onChange}}) => (
                            <Dropdown
                                value={value}
                                onChange={onChange}
                                options={prefixes}
                                name="phone_prefix"
                                className="w-14"/>
                        )} name="phone_prefix"/>
                        <div className="w-full">
                            <Controller render={({field: {value, onChange}}) => (
                                <InputText
                                    className="w-full"
                                    value={value}
                                    onChange={onChange}
                                    id="phone"
                                    aria-describedby="phone"
                                    placeholder={text[lang].mobile_phone}
                                />
                            )} name="phone" control={control}/>
                            {errors.phone && (
                                <small className="form-element-is-invalid">
                                    {text[lang].field_required}
                                </small>
                            )}
                        </div>
                    </div>
                </div>
                <Controller rules={{
                    required: true
                }} control={control} render={({field: {value, onChange}}) => (
                    <div className="col-12 md:col-6">
                        <label htmlFor="email">{text[lang].email}</label>
                        <InputText
                            className="w-full"
                            value={value}
                            onChange={onChange}
                            id="email"
                            aria-describedby="email"
                            placeholder={text[lang].email}
                        />
                        {errors.email && (
                            <small className="form-element-is-invalid">
                                {text[lang].field_required}
                            </small>
                        )}
                    </div>
                )} name="email"/>
                <Controller control={control} render={({field: {value, onChange}}) => (
                    <div className="col-12 md:col-6">
                        <label htmlFor="duty_id">{text[lang].position}</label>
                        <Dropdown
                            value={value}
                            onChange={onChange}
                            options={duties}
                            className="w-full"
                            name="duty_id"/>
                        {errors.duty_id && (
                            <small className="form-element-is-invalid">
                                {text[lang].field_required}
                            </small>
                        )}
                    </div>
                )} name="duty_id"/>
                <Controller control={control} render={({field: {value, onChange}}) => (
                    <div className="col-12 md:col-6">
                        <label htmlFor="birth">{text[lang].birth_date}</label>
                        <Calendar
                            locale={lang}
                            dateFormat="dd/mm/yy"
                            placeholder={text[lang].birth_date}
                            className="w-full" value={value}
                            onChange={(e) => onChange(e.value)}/>
                        {errors.birth && (
                            <small className="form-element-is-invalid">
                                {text[lang].field_required}
                            </small>
                        )}
                    </div>
                )} name="birth"/>
                <Controller control={control} render={({field: {value, onChange}}) => (
                    <div className="col-12 md:col-6">
                        <label htmlFor="role">{text[lang].role}</label>
                        <Controller control={control} render={({field: {value, onChange}}) => (
                            <Dropdown
                                value={value}
                                onChange={onChange}
                                options={roles}
                                name="role"
                                className="w-full"/>
                        )} name="role"/>
                        {errors.role && (
                            <small className="form-element-is-invalid">
                                {text[lang].field_required}
                            </small>
                        )}
                    </div>
                )} name="role"/>
                <div className="w-full">
                    <Controller control={control} render={({field: {value, onChange}}) => (
                        <div className="col-12">
                            <label htmlFor="note">{text[lang].note}</label>
                            <InputTextarea
                                className="w-full"
                                value={value}
                                onChange={onChange}
                                id="note"
                                aria-describedby="note"
                                placeholder={text[lang].note}
                            />
                            {errors.note && (
                                <small className="form-element-is-invalid">
                                    {text[lang].field_required}
                                </small>
                            )}
                        </div>
                    )} name="note"/>
                </div>
                <div className="col-12">
                    <div className="flex justify-content-end gap-2">
                        <Button className="p-button-outlined p-button-sm" onClick={e => {
                            e.preventDefault()
                            setModal(null)
                        }}>{text[lang].close}</Button>
                        <Button className="p-button-danger p-button-sm" disabled={loader}>
                            {loader && <i className="pi pi-spin pi-spinner mr-1"/>}
                            {text[lang].save}
                        </Button>
                    </div>
                </div>
            </form>
        )
    )
}

export default WorkerForm
