import './index.scss'
import Avatar from '../../components/core/avatar'
import { useEffect, useState } from 'react'
import { Calendar } from 'primereact/calendar'
import text from '../../data/text.json'
import moment from 'moment'
import { Dropdown } from 'primereact/dropdown'
import { Dialog } from 'primereact/dialog'
import PatientForm from './PatientForm'
import Select from '../../api/select'
import { ProgressSpinner } from 'primereact/progressspinner'
import Patient from '../../api/patient'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../routes'
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog'

const Patients = () => {
  const lang = 'az'
  const [pickerTime, setPickerTime] = useState(new Date())
  const [formIsActive, setFormIsActive] = useState(false)
  const [doctors, setDoctors] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const [meetings, setMeetings] = useState([])
  const [excelLink, setExcelLink] = useState('')
  const navigate = useNavigate()

  const dayIndex = new Date(pickerTime).getDay()

  let hours = [
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30',
    '19:00',
    '19:30'
  ]

  if (dayIndex === 0) {
    hours = [
      '11:00',
      '11:30',
      '12:00',
      '12:30',
      '13:00',
      '13:30',
      '14:00',
      '14:30',
      '15:00',
      '15:30'
    ]
  }

  const fetchDoctors = async () => {
    const data = await Select.getDoctors()
    setDoctors(data)
  }

  const fetchMeetings = async () => {
    const data = await Patient.getAllDoctorMeetings({
      date: moment(pickerTime).format('YYYY-MM-DD')
    })
    setMeetings(data)
  }

  const fetchData = async () => {
    setIsFetching(true)
    await Promise.all([fetchDoctors(), fetchMeetings(), getExcel()])
    setIsFetching(false)
  }

  const navigateToDetailPage = id => {
    navigate(routes.doctor_patients.path.replace(':id', id))
  }

  const deleteMeet = async id => {
    try {
      await Patient.delete(id)
      fetchData()
    } catch (e) {
      console.log('Error')
    }
  }

  const openConfirm = (e, id) => {
    e.stopPropagation()
    confirmDialog({
      target: e.currentTarget,
      message: text[lang].are_you_sure_delete,
      icon: 'pi pi-exclamation-triangle',
      accept: () => deleteMeet(id),
      acceptLabel: text[lang].yes,
      rejectLabel: text[lang].no,
      acceptClassName: 'p-button-danger'
    })
  }

  const getFullName = meet => {
    const fullname = meet?.name + ' ' + meet?.surname
    if (fullname?.length > 20) {
      return fullname.substring(0, 20) + '...'
    }
    return fullname
  }

  const getExcel = async () => {
    try {
      const data = await Patient.exportAllMeetingsExcel({
        date: moment(pickerTime).format('YYYY-MM-DD')
      })
      setExcelLink(URL.createObjectURL(data))
    } catch (e) {
      console.log('Error')
    }
  }

  useEffect(() => {
    fetchData()
  }, [pickerTime])

  return (
    isFetching ? (
      <div className="flex justify-content-center pt-5 w-full">
        <ProgressSpinner/>
      </div>
    ) : (
      <div className="patient-page w-full">
        <ConfirmDialog/>
        <Dialog
          header={formIsActive?.id ? text[lang].edit_patient : text[lang].new_patient}
          visible={formIsActive}
          draggable={false}
          onHide={() => setFormIsActive(null)}
          style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
          <PatientForm fetchData={fetchData} modal={formIsActive} doctors={doctors}
                       setModal={setFormIsActive}/>
        </Dialog>
        <div className="flex flex-column gap-2 md:flex-row md:align-items-center justify-content-between p-5">
          <div className="flex flex-column md:flex-row md:align-items-center gap-2">
            <div className="flex flex-column">
              <label htmlFor="picker">{text[lang].date}</label>
              <Calendar
                locale={lang}
                id="picker"
                dateFormat="dd/mm/yy"
                placeholder={text[lang].date}
                className="w-full"
                value={pickerTime}
                onChange={(e) => setPickerTime(e.value)}/>
            </div>
          </div>
          <div className="flex flex-column md:flex-row gap-2">
            <Dropdown
              value="all"
              onChange={e => navigateToDetailPage(e?.value)}
              options={[{
                value: 'all',
                label: text[lang].all_doctors
              }, ...doctors]}
              name="prefix"
              className="md:w-14rem"/>
            <a href={excelLink} download="report.xlsx" className="p-button p-button-outlined flex gap-1">
              <i className="pi pi-print"/>
              <span>{text[lang].print}</span>
            </a>
          </div>
        </div>
        <div className="w-full">
          <div className="calendar-container">
            <div className="calendar">
              <div className="left-bar" id="left-bar">
                {hours.map((item) => (
                  <div className="cell">{item}</div>
                ))}
              </div>
              <div className="right-bar" id="right-bar">
                <div className="calendar-header" style={{ minWidth: `${158 * meetings?.length}px` }}>
                  {meetings?.doctors?.map((item) => (
                    <div onClick={() => navigateToDetailPage(item?.value)}
                         className="calendar-header-cell justify-content-center pl-2"
                         key={item?.value}>
                      <div className="flex gap-2 align-items-center cursor-pointer">
                        <Avatar user={item}/>
                        <div className="flex flex-column">
                          <span>{item?.name} {item?.surname}</span>
                          <span>{item?.duty_label}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="calendar-body">
                  {hours?.map(item => (
                    <div className="body-cell"
                         style={{ minWidth: `${158 * meetings?.doctors?.length}px` }}>
                      {meetings?.meetings?.[item]?.map(meet => (
                        <div className="body-cell-children" onClick={() => setFormIsActive({
                          ...meet,
                          date: pickerTime,
                          hour: meet?.hour_value
                        })}>
                          {meet?.id && (
                            <div className={classNames({
                              box: true,
                              blue: meet?.is_regular !== 1,
                              yellow: meet?.is_regular === 1,
                              green: !meet?.created_by
                            })}>
                              {meet?.not_work !== 1 ? (
                                <>
                                  <span>{meet?.hour_label}</span>
                                  <span
                                    title={`${meet?.name} ${meet?.surname}`}>{getFullName(meet)}</span>
                                  <span className="status">{meet?.status_label}</span>
                                </>
                              ) : (
                                <>
                                  <span>{meet?.hour_label}</span>
                                  <span>{text[lang].i_am_not_join}</span>
                                </>
                              )}
                              <span className="icons">
                                                                {meet?.can_edit && <i className="pi pi-pencil edit"/>}
                                {meet?.can_delete && <i className="pi pi-trash delete"
                                                        onClick={e => openConfirm(e, meet?.id)}/>}
                                                            </span>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default Patients
